import { Button, Checkbox, Collapse, DatePicker, Form, Input, InputNumber, message, Select } from "antd";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCustomer, loadAllCustomer, loadSingleCustomer } from "../../redux/rtk/features/customer/customerSlice";
import { loadProduct } from "../../redux/rtk/features/product/productSlice";
import { addSale, addSaleInvoiceDraft, deleteSaleInvoiceDraft, loadDefaultManualId, updateSaleInvoiceDraft } from "../../redux/rtk/features/sale/saleSlice";
import Products from "./Products";
import "./sale.css";
import dayjs from "dayjs";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loadAllSale } from "../../redux/rtk/features/sale/saleSlice";
import { loadAllStaff } from "../../redux/rtk/features/user/userSlice";
import { loadAllVatTax } from "../../redux/rtk/features/vatTax/vatTaxSlice";
import BigDrawer from "../Drawer/BigDrawer";
import AddCust from "../customer/addCust";
import getStaffId from "../../utils/getStaffId";
import _ from "lodash";
import { DECIMAL_NUMBER } from "../../utils/functions";
import { RenderAdditionalDetails } from "./UpdateSale";
import MOPSelector from "../MOPModal/MOPSelector";
import { MODE_OF_PAYMENTS, textEditorFormats, textEditorModule } from "../../utils/constants";
import SaleInvoiceA4 from "../Invoice/SaleInvoiceA4";
import SaleInvoiceA5 from "../Invoice/SaleinvoiceA5";
import SaleInvoice from "../Invoice/SaleInvoice";
import SaleInvoiceV1 from "../Invoice/SaleInvoiceV1";
import SaleInvoiceV1A5 from "../Invoice/SaleInvoiceV1A5";
import PosPrint from "../Invoice/PosPrint";
import PosPrint1 from "../Invoice/PosPrint1";
import PosPrint3inch from "../Invoice/PosPrint3inch";
import PosPrint4inch from "../Invoice/PosPrint4inch";
import PosPrint3inch1 from "../Invoice/PosPrint3inch1";
import PosPrint4inch1 from "../Invoice/PosPrint4inch1";
import SaleInvoiceV2A4 from "../Invoice/SaleInvoiceV2A4";
import SaleInvoiceV3A4 from "../Invoice/SaleInvoiceV3A4";
import { handleSendInvoice } from "../pos/AddPos.utils";
import ReactQuill from "react-quill";

const AddSale = ({ setOpen }) => {
	const { Option } = Select;
	const [loader, setLoader] = useState(false);

	const { state: routerState } = useLocation();

	const [selectedCustomerId, setSelectedCustomerId] = useState();
	const [openAddCustomer, setOpenAddCustomer] = useState(false);
	const defaultSendInvoiceToCustomer = localStorage.getItem("sendInvoiceToCustomer") == "true";

	const [productsSerialNos, setProductsSerialNos] = useState({});

	const [terms, setTerms] = useState("");

	const [state, setState] = useState({
		subTotal: 0,
		total: 0,
		afterDiscount: 0,
		afterVatTaxAdded: 0,
		totalVatAmount: 0,
		returnTotal: 0,
		poNumber: "",
		poDate: "",
		vehicleNumber: "",
		deliveryNoteDate: "",
		destination: "",
		buyerDated: "",
		totalQuantity: 0
	})
	const updateState = (key, value) => {
		setState(prevState => ({
			...prevState,
			[key]: value
		}))
	}

	const navigate = useNavigate();
	const dispatch = useDispatch();
	// Form Function
	const [form] = Form.useForm();
	const callBackRef = useRef({});
	const selectProductButtonRef = useRef(null);
	const invoiceContainerRef = useRef();
	const sendInvoiceToCustomerFlagRef = useRef();

	const allCustomer = useSelector((state) => state.customers.list);
	const { list: productList, loading: productLoading } = useSelector(
		(state) => state.products
	);
	const customer = useSelector((state) => state.customers.customer);
	const invoiceData = useSelector((state) => state.accounts.appSettings);
	const productSettings = useSelector((state) => state.accounts.productSettings);
	const templateData = useSelector((state) => state.accounts.templateSettings);

	const staffId = getStaffId();
	const [userId, setUserId] = useState(staffId);
	const [savedSale, setSavedSale] = useState({});
	const noOfDecimalDigits = _.get(productSettings, "noOfDecimalDigits", 0);

	const allStaff = useSelector((state) => state.users.list);
	const { defaultManualId } = useSelector((state) => state.sales);
	const { list: vatTaxList, loading: vatTaxLoading } = useSelector(
		(state) => state.vatTax
	);
	useEffect(() => {
		dispatch(loadAllStaff({ status: "true" }));
		dispatch(loadAllCustomer({ page: 1, count: 100000 }));
		dispatch(loadProduct({ page: 1, count: 100000 }));
		dispatch(loadAllVatTax());
	}, [dispatch]);

	// to handle convert quotation to sale
	useEffect(()=>{
		if(routerState?.data){
			form.setFieldsValue({ ...routerState.data, date: dayjs(routerState.data.date) });
			totalCalculator()
		}
	},[routerState]);

	// to load default manual id conditionally
	const loadManualId = () => {
		if (invoiceData?.showManualIdOnSale) {
			dispatch(loadDefaultManualId());
		}
	}

	useEffect(() => {
		loadManualId();
		if (invoiceData?.terms_conditions) {
			form.setFieldValue("terms_conditions", invoiceData.terms_conditions);
			setTerms(invoiceData.terms_conditions);
		}
	}, [invoiceData]);

	// to set default manual id
	useEffect(() => {
		if (defaultManualId) {
			form.setFieldValue("manualId", defaultManualId);
		}
	}, [defaultManualId]);

	useEffect(() => {
		if (savedSale?.manualId) {
			form.setFieldValue("sendInvoiceToCustomer", sendInvoiceToCustomerFlagRef.current);
			if (sendInvoiceToCustomerFlagRef.current) {
				handleSendInvoice(savedSale.id, invoiceContainerRef);
				form.resetFields();
				form.setFieldValue("customerId", selectedCustomerId);
				if (invoiceData.redirectToPreview) {
					navigate(`/admin/sale/${savedSale.id}`);
				}
			}
			if (_.isFunction(callBackRef.current)) {
				if (invoiceData.redirectToPreview) {
					setOpen?.(false);
				}
				callBackRef.current();
				resetForm()
				selectProductButtonRef.current.click();
				setSavedSale(null);
			}
		}
	}, [savedSale]);

	useEffect(() => {
		if (selectedCustomerId) {
			dispatch(loadSingleCustomer(selectedCustomerId));
		}
		return () => {
			dispatch(clearCustomer());
		};
	}, [selectedCustomerId]);	

	const resetForm = ()=>{
		form.resetFields();
		updateState("total", 0);
		updateState("subTotal", 0);
		updateState("afterDiscount", 0);
		updateState("afterVatTaxAdded", 0);
		form.setFieldValue("customerId", selectedCustomerId);
	}

	const onFormSubmit = async (values) => {
		try {
			
			const filteredArray = values.saleInvoiceProduct.filter(obj => (obj.productId !== undefined));

			if (_.isEmpty(filteredArray)) {
				message.warning("Please add quanity on atleast one product");
				setLoader(false);
				return 
			}
			const printFlag = form.getFieldValue("printFlag");
			sendInvoiceToCustomerFlagRef.current = form.getFieldValue("sendInvoiceToCustomer");

			const data = {
				...values,
				userId: userId,
				saleInvoiceProduct: filteredArray,
				customerExistingDue: customer?.dueAmount,
				productsSerialNos
			};
			if (values.modeOfPayment !== _.last(MODE_OF_PAYMENTS).value) {
				_.set(data, "modeOfPayments", [{
					modeOfPayment: values.modeOfPayment,
					amount: values.paidAmount
				}])
			}

			const resp = await dispatch(addSale(data));

			if (resp.payload.message === "success") {
				setLoader(false);
				toast.success("New Product Sold ");
				setSavedSale(_.get(resp, "payload.newData", {}));
				if (routerState?.draftId) {
					dispatch(deleteSaleInvoiceDraft({ id: routerState.draftId }));
				}
				delete
				dispatch(
					loadAllSale({
						page: 1,
						count: "",
						startdate: moment().startOf("month").format("YYYY-MM-DD"),
						enddate: moment().endOf("month").format("YYYY-MM-DD"),
						user: "",
					})
				);
				loadManualId()
				localStorage.setItem("sendInvoiceToCustomer", sendInvoiceToCustomerFlagRef.current);
				if (!printFlag && !sendInvoiceToCustomerFlagRef.current) {
					if (invoiceData.redirectToPreview) {
						navigate(`/admin/sale/${resp.payload.createdInvoiceId}`);
					}
					resetForm();
					selectProductButtonRef.current.click();
				}
			} else {
				toast.error(resp.payload.message || "Error while sales");
				setLoader(false);
			}

		} catch (error) {
			setLoader(false);
			toast.error("Error while sales");
		}
	};

	// validation handlers
	const validatePaidAmount = (_, value) => {
		if (value >= 0) {
			return Promise.resolve();
		}
		return Promise.reject(
			`Paid amount must be greater than or equal ${state.afterVatTaxAdded}`
		);
	};

	const validateDiscount = (_, value) => {
		if (value >= 0 && value <= state.total) {
			return Promise.resolve();
		}
		return Promise.reject(`Discount must be greater than or equal to ${state.total}`);
	};

	useEffect(() => {
		if (state.afterVatTaxAdded > 0) {
			form.setFieldValue("paidAmount", state.afterVatTaxAdded);
		} else {
			form.setFieldValue("paidAmount", 0);
		}
	}, [state.afterVatTaxAdded]);

	// total calculate
	const totalCalculator = () => {
		const productArray = form.getFieldValue("saleInvoiceProduct");
		let totalVatAmount = 0;
		let totalQuanity = 0;
		const subTotal = productArray?.reduce((subTotal, current) => {
			const quantity = current?.productQuantity || 0;
			const price = current?.productSalePrice || 0;
			let vat = current?.vatPrice || 0;
			const discount = current?.discount_type == 'amount' ? current?.discount : (price * (current?.discount / 100) || 0)
			// const totalVat = (vat / 100) * price * quantity;
			if (current?.inclusiveGst) {
				totalVatAmount += (price - price / (1 + vat / 100)) * quantity || 0;
				vat = 0;
			} else {
				totalVatAmount += ((price * quantity) * (vat / 100)) || 0;
			}
			const totalVat = ((price * quantity)  * (vat/ 100 )) || 0;
			totalQuanity += quantity

			return [
				...subTotal,
				{
					subVat: current?.productVat || 0,
					// subDiscount: current?.productVat || 0quantity,
					discount: current?.discount || 0,
					discount_type : current?.discount_type || 0,
					subPrice: ((price * quantity) + totalVat ) || 0,
					productMrpPrice : current?.productMrpPrice,
					productPurchasePrice: current?.product?.productPurchasePrice,
				},
			];
		}, []) || [];

		updateState("totalQuantity", totalQuanity);
		updateState("subTotal", subTotal);
		updateState("totalVatAmount", totalVatAmount);

		const total = DECIMAL_NUMBER(subTotal.reduce((total, current) => total + (current.subPrice > 0 ? current.subPrice : 0), 0) || 0, noOfDecimalDigits);

		const returnTotalSum = Math.abs(subTotal.reduce((total, current) => total + (current.subPrice < 0 ? current.subPrice : 0), 0) || 0);
		updateState("returnTotal", returnTotalSum);
		form.setFieldValue("returnTotal", returnTotalSum);

		// const result = subTotal.reduce((acc, item) => {
			
		// 	acc.cgst += item.cgst || 0; 
		// 	acc.sgst += item.sgst || 0;
		// 	acc.igst += item.igst || 0;
		// 	acc.product_discount += item.discount || 0; 
		// 	const price = parseFloat(item.price) || 0;
		// 	const qty = parseFloat(item.qty) || 0;

		// 	acc.subtotal = acc.subPrice + price * qty;

		// 	let tax = 0;
		// 	acc.same_state = false;

		// 	if (distributor_type == 0) {
		// 	  if (warehouse_detail.state_id === shipping_state_id) {
		// 		acc.same_state = true;
		// 	  }
		// 	} else if (shipping_state_id === stockist_state_id) {
		// 	  acc.same_state = true;
		// 	}

		// 	if (acc.same_state === true) {
		// 	  tax = acc.sgst + acc.cgst;
		// 	} else {
		// 	  tax = acc.igst;
		// 	}

		// 	acc.totalTax = tax;

		// 	return acc;
		//   }, {
		// 	subtotal: 0,
		// 	product_discount: 0,
		// 	cgst: 0,
		// 	sgst: 0,
		// 	igst: 0,
		// 	totalTax: 0,
		// 	same_state: false,
		//   });


		updateState("total", total);

		const discount = form.getFieldValue("discount") || 0;

		const discount_type = form.getFieldValue("discount_type") || 0;

		let afterDiscount = 0

		if (discount_type == 'percentage') {
			afterDiscount = DECIMAL_NUMBER((Boolean(total) ? total - (total * (discount / 100)) || 0 : 0) - returnTotalSum, noOfDecimalDigits);
		} else {
			afterDiscount = DECIMAL_NUMBER((Boolean(total) ? total - (discount) || 0 : 0) - returnTotalSum, noOfDecimalDigits);
		}


		updateState("afterDiscount", afterDiscount);

		// GST calculate
		const vatFields = form.getFieldValue("vatId");
		const totalVatArray =
			vatFields?.map((id) => {
				return vatTaxList.find((item) => id === item.id)?.percentage;
			}) || [];
		const TotalTaxVatPercent = totalVatArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
		const afterVatTaxAdded = DECIMAL_NUMBER(afterDiscount + (TotalTaxVatPercent / 100) * total, noOfDecimalDigits);
		updateState("afterVatTaxAdded", afterVatTaxAdded);
		//due count
		const due = DECIMAL_NUMBER(Boolean(afterVatTaxAdded) ? afterVatTaxAdded - (form.getFieldValue("paidAmount") || 0) || 0 : 0, noOfDecimalDigits);
		// form.setFieldValue('paidAmount',afterVatTaxAdded)
	};

	useEffect(() => {
		if (!form.getFieldValue('customerId') && allCustomer && allCustomer?.length) {
			form.setFieldValue('customerId', allCustomer?.[0]?.id);
			setSelectedCustomerId(allCustomer?.[0]?.id);
		}
	}, [allCustomer]);

	if(!form.getFieldValue('userId')&& allStaff && allStaff?.length){
		form.setFieldValue('userId',allStaff?.[0]?.id)
	}

	const handleAddCustomerSuccess = () => {
		setOpenAddCustomer(false);
	}

	const prodTermsHandler = (val) => {
		setTerms(val);
	};

	const handleSaveDraft = async (e) => {
		setLoader(true);
		const values = form.getFieldsValue();
		const filteredArray = values.saleInvoiceProduct.filter(obj => obj?.productId !== undefined);
		const data = {
			...values,
			userId: userId,
			saleInvoiceProduct: filteredArray,
			customerExistingDue: customer?.dueAmount
		};
		if (values.modeOfPayment !== _.last(MODE_OF_PAYMENTS).value) {
			_.set(data, "modeOfPayments", [{
				modeOfPayment: values.modeOfPayment,
				amount: values.paidAmount
			}])
		}
		let resp;
		if (routerState?.draftId) {
			resp = await dispatch(updateSaleInvoiceDraft({ values: data, draftId: routerState.draftId }));
		} else {
			resp = await dispatch(addSaleInvoiceDraft(data));
		}
		setLoader(false);
		if (resp.payload.message === "success") {
			toast.success("Draft Saved");
			loadManualId()
			resetForm();
			selectProductButtonRef.current.click();
		} else {
			toast.error(resp.payload.message || "Error while sales");
		}
	}

	return (
		<Form
			form={form}
			name='dynamic_form_nest_item'
			onFinish={onFormSubmit}
			onFinishFailed={() => {
				setLoader(false);
			}}
			layout='vertical'
			size='large'
			autoComplete='off'
			initialValues={{
				paidAmount: 0,
				discount: 0,
				discount_type: "percentage",
				date: dayjs(),
				vatId: [],
			}}>
			<div className="max-lg:overflow-x-scroll">
				<div className="max-lg:w-[80rem]">	
			 <Products
				form={form}
				totalCalculator={totalCalculator}
				subTotal={state.subTotal}
				productList={productList}
				productLoading={productLoading}
				invoiceData={invoiceData}
				selectProductButtonRef={selectProductButtonRef}
				productsSerialNos={productsSerialNos}
				setProductsSerialNos={setProductsSerialNos}
			/>
			</div>
			</div>
			<div className='flex max-sm:flex-wrap mb-2 gap-6 max-sm:static'>
				<div className='w-1/2 max-sm:w-full'>
					<div className='flex gap-3 items-center'>
						<Form.Item
							label='Customer'
							className='w-full'
							name='customerId'
							rules={[
								{
									required: true,
									message: "Please Select a Customer!",
								},
							]}>
							<Select
								className='w-full'
								loading={!allCustomer}
								showSearch
								placeholder='Select a customer'
								optionFilterProp='children'
								onChange={(e) => setSelectedCustomerId(e)}
								// defaultValue={allCustomer && allCustomer?.length ? allCustomer[0].id : undefined}
								// filterOption={(input, option) => {
								// 	// option.children.toLowerCase().includes(input.toLowerCase())
								// 	 const name = option['data-name'].toLowerCase();
                                //    // const phone = option['data-state'].toLowerCase();
                                //      const searchText = input.toLowerCase();
                                //      return name.includes(searchText);
								// }}
								>
                             	{allCustomer &&
									allCustomer?.map((sup) => (
										<Option key={sup.id} value={sup.id} data-name={sup.name}>
											{sup.name} - {sup.phone} 
											
										</Option>
									))}
							</Select> 
						</Form.Item>
						<BigDrawer
							className={"-mb-[36px]"}
							title={"new Customer"}
							btnTitle={"customer"}
							openFlag={openAddCustomer}
							setOpenFlag={setOpenAddCustomer}
							children={<AddCust drawer={true} onSuccess={handleAddCustomerSuccess} />}
						/>
					</div>
                    
					<div className='flex gap-5'>
						{
							invoiceData?.showManualIdOnSale &&
							<Form.Item
								className='w-2/4'
								label='Manual Id'
								name='manualId'
								required
								rules={[
									{
										required: true,
										message: "Please enter manual id!",
									},
								]}>
								<Input
									className=''
									placeholder='Enter Manual ID'
									size={"small"}
								/>
							</Form.Item>
						}
                        {
							invoiceData?.show_table_number &&
                            <Form.Item  
								className='w-1/4' label='Table No' name='tableNo'>
								<Input
									className=''
									placeholder='Enter Table Number'
									size={"small"}
								/>
							</Form.Item>
                        }
						<div className="flex w-1/4 pb-1.5 items-end gap-6 justify-end">
							<div className="mb-1">Whatsapp : </div>
							<Form.Item
								name='sendInvoiceToCustomer'
								style={{ height: "34px" }}
								initialValue={defaultSendInvoiceToCustomer}
								valuePropName="checked"
							>
								<Checkbox />
							</Form.Item>
						</div>
					</div>
					{
						invoiceData?.showInvoicePrefix &&
						<Form.Item
							className=''
							label='Invoice Prefix'
							name='invoicePrefix'
							required
							rules={[
								{
									required: true,
									message: "Please enter invoice prefix!",
								},
							]}>
							<Input
								className=''
								placeholder='Enter Invoice Prefix '
								size={"small"}
							/>
						</Form.Item>
					}

					<div className='flex gap-5'>
						<Form.Item
							label='Date'
							required
							className='w-1/2'
							name='date'
							rules={[
								{
									required: true,
									message: "Please input Date!",
								},
							]}>
							<DatePicker
								style={{ marginBottom: "10px" }}
								label='date'
								size='small'
								defaultValue={dayjs()}
								format={"YYYY-MM-DD"}
							/>
						</Form.Item>

						<Form.Item
							className='w-1/2'
							label='Sales Person '
							name='userId'
							required>
							<Select
								className='w-full'
								loading={!allStaff}
								showSearch
								placeholder='Select sales person '
								optionFilterProp='children'
								defaultValue={allStaff && allStaff[0] ? allStaff[0].id : undefined}
								onChange={(value) => setUserId(value)}
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}>
								{allStaff &&
									allStaff?.map((info) => (
										<Option key={info.id} value={info.id}>
											{info.username}
										</Option>
									))}
							</Select>
						</Form.Item>
					</div>

					<Form.Item className='' label='Shipping Address' name='address'>
						<Input
							className=''
							placeholder='Enter shipping address'
							size={"small"}
							// style={{ marginBottom: "10px" }}
						/>
					</Form.Item>

					<Collapse
						className='sale-additional-details'
						size="small"
						items={[{ key: '1', label: 'Additional Details', children: <RenderAdditionalDetails updateState={updateState} /> }]}
					/>

					{/* <Form.Item className='' label='Note' name='note'>
						<Input
							className=''
							size={"small"}
							placeholder='Write sale Note'
							style={{ marginBottom: "10px" }}
							label='note'
						/>
					</Form.Item> */}

					<Form.Item
						style={{ marginBottom: "10px" }}
						name='terms_conditions'
						label='Terms & Conditions '>
						<ReactQuill
							value={terms}
							onChange={prodTermsHandler}
							modules={textEditorModule}
							formats={textEditorFormats}
						/>
					</Form.Item>

					{/* {invoiceData?.terms_conditions && <div>
						<label className="text-base my-2">Terms & Conditions</label>
						<div dangerouslySetInnerHTML={{__html: invoiceData?.terms_conditions}}></div>
					</div>} */}
				</div>
				<div className='w-1/2 max-sm:w-full'>
					{" "}
					<div className='py-1'>
						<div className='py-0.5 flex justify-between dark:text-white'>
							<strong>Total: </strong>
							<strong>{DECIMAL_NUMBER(state.total, noOfDecimalDigits)} </strong>
						</div>
						{
							state.returnTotal > 0 &&
							<div className='p-0.5 flex items-centerl justify-between dark:text-white'>
								<span>Return: </span>
								<Form.Item name="returnTotal">
									<Input disabled />
								</Form.Item>
							</div>
						}
						<div className='flex justify-between items-center dark:text-white'>
							<span className=''>Discount: </span>
							<div className="flex gap-2">
								<Form.Item
									className='mb-0'
									name='discount'
									rules={[
										{
											validator: validateDiscount,
										},
									]}>
									<InputNumber
										className='w-[8.8rem]'
										size={"small"}
										placeholder="Amount (₹)"
										onChange={totalCalculator}
										defaultValue={0}
										min={0}
									/>
								</Form.Item>
								<Form.Item className='mb-0 w-4/5 flex justify-end discount_type' name='discount_type'>
									<Select
										className='w-[8.8rem] !h-[44px] !flex !items-center'
										placeholder='Select Discount type'
										maxTagCount={5}
										showArrow
										size={"small"}
										onChange={() => totalCalculator()}>
										{["percentage", "amount"]?.map((item) => (
											<Option key={item} value={item}>
												{item}
											</Option>
										))}
									</Select>
								</Form.Item>
							</div>
						</div>
						<div className='py-0.5 flex justify-between items-center dark:text-white'>
							<span>After Discount: </span>
							<span>{DECIMAL_NUMBER(state.afterDiscount, noOfDecimalDigits)}</span>
						</div>
						{/* <div className='flex justify-between items-center mb-2 dark:text-white'>
							<span className=''>GST: </span>
							<Form.Item className='mb-0 w-4/5 flex justify-end dark:text-white' name='vatId'>
								<Select
									mode='multiple'
									className='w-72 '
									placeholder='Select GST type'
									maxTagCount={5}
									showArrow
									size={"small"}
									loading={vatTaxLoading}
									onChange={() => totalCalculator()}>
									{vatTaxList?.map((item) => (
										<Option key={item.id} value={item.id}>
											{item.title}
											<span className='italic'>@{item.percentage}%</span>
										</Option>
									))}
								</Select>
							</Form.Item>
						</div> */}
						<div className='py-0.5 flex justify-between items-center dark:text-white'>
							<span>Total Payable: </span>
							<span>{DECIMAL_NUMBER(state.afterVatTaxAdded, noOfDecimalDigits)}</span>
						</div>
						<div className='py-0.5 flex justify-between items-center dark:text-white'>
							<span>Total Quantity: </span>
							<span>{state.totalQuantity}</span>
						</div>
						<div className='flex justify-between items-center dark:text-white'>
							<span className=''>Paid Amount: </span>
							<Form.Item
								className='mb-0'
								name='paidAmount'
								normalize={(value) => (value === null ? 0 : value)}
								rules={[
									{
										validator: validatePaidAmount,
									},
								]}>
								<InputNumber
									className='w-72'
									onChange={() => totalCalculator()}
									defaultValue={0}
									placeholder="Paid Amount"
									min={0}
									size={"small"}
								/>
							</Form.Item>
						</div>
						<div className='py-0.5 flex justify-between dark:text-white'>
							<strong>Customer Due:</strong>
							<strong>{customer?.dueAmount ? customer?.dueAmount?.toFixed(2) : "0"}</strong>
						</div>
						<div className=' flex justify-between dark:text-white'>
							<MOPSelector form={form} totalAmount={form.getFieldValue("paidAmount")} dropdownWidth="50%" />
						</div>
					</div>
				  <div className=" grid grid-cols-2 gap-4 mt-2">
					<div className='col-span-1'>
						{templateData?.invoiceTemplate === 'a4medic' && (
                        <div className={"text-end flex-1"}>
							<SaleInvoiceA4
								data={{ ...state, ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
						)}
                        {templateData?.invoiceTemplate === 'a5medic' && (
                        <div className={"text-end flex-1"}>
							<SaleInvoiceA5
								data={{ ...state, ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
						)}
						{templateData?.invoiceTemplate === 'a4print' && (
						<div className={"text-end flex-1"}>
							<SaleInvoice
								data={{ ...state, ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
						)}
						{templateData?.invoiceTemplate === 'v1a4print' && (
						<div className={"text-end flex-1"}>
							<SaleInvoiceV1
								data={{ ...state, ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
						)}
						{templateData?.invoiceTemplate === 'v1a5print' && (
						<div className={"text-end flex-1"}>
							<SaleInvoiceV1A5
								data={{ ...state, ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
						)}
						{templateData?.invoiceTemplate === 'v2a4print' && (
						<div className={"text-end flex-1"}>
							<SaleInvoiceV2A4
								data={{ ...state, ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded, productsSerialNos }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
						)}
						{templateData?.invoiceTemplate === 'v3a4print' && (
						<div className={"text-end flex-1"}>
							<SaleInvoiceV3A4
								data={{ ...state, ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
						)}
                        {templateData?.invoiceTemplate === '3posprint' && (
                         <div className={"text-end flex-1"}>
							<PosPrint
								data={{ ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
					    )}
						<div className={"text-end flex-1"}>
							<PosPrint1
								data={{ ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded }}
								vatAmount={state.totalVatAmount}
								invoiceContainerRef={invoiceContainerRef}
								styles={{ width: "100%" }}
								hideBtn={templateData?.invoiceTemplate === '4posprint' ? false : true}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
						{templateData?.invoiceTemplate === 'PosPrint3inch' && (
                         <div className={"text-end flex-1"}>
							<PosPrint3inch
								data={{ ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded, productsSerialNos }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
					    )}
						{templateData?.invoiceTemplate === 'PosPrint4inch' && (
                         <div className={"text-end flex-1"}>
							<PosPrint4inch
								data={{ ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded, productsSerialNos }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
					    )}
						{templateData?.invoiceTemplate === '3inch' && (
                         <div className={"text-end flex-1"}>
							<PosPrint3inch1
								data={{ ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
					    )}
						{templateData?.invoiceTemplate === '4inch' && (
                         <div className={"text-end flex-1"}>
							<PosPrint4inch1
								data={{ ...savedSale, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == selectedCustomerId), grandTotal: state.afterVatTaxAdded }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								preCall={(callBack) => {
									form.setFieldValue("printFlag", true);
									callBackRef.current = callBack;
									form.submit()
								}}
							/>
						</div>
					    )}
					</div>
                    <div className="col-span-1">
					<Form.Item >
						<Button
							// disabled={loader}
							block
							type='primary'
							htmlType='submit'
							loading={loader}
							onClick={() => {
								setLoader(true);
							}}>
							Save & Preview
						</Button>
					</Form.Item>
					</div>
				  </div>
					<div className="col-span-1 mt-4">
						<Form.Item >
							<Button
								// disabled={loader}
								block
								type='primary'
								// htmlType='submit'
								loading={loader}
								onClick={handleSaveDraft}>
								Save As Draft
							</Button>
						</Form.Item>
					</div>
				</div>
			</div>
		</Form>
	);
};

export default AddSale;

import { Button } from "antd";
import React, {
	forwardRef,
	Fragment,
	useRef,
} from "react";
import { useReactToPrint } from "react-to-print";
// import "./posPrint.css";
import _ from "lodash";
import { DECIMAL_NUMBER, getMomentObj, isValidUrl } from "../../utils/functions";
import { useSelector } from "react-redux";

const PrintToPdf = forwardRef(({ data, invoiceData, vatAmount, isQuotation, productSettings }, ref) => {
	const storeGSTCode = (invoiceData?.gst_no)?.slice(0, 2);
	const customerGSTCode = (data?.customer?.gst_no)?.slice(0, 2);
	const showMrp = invoiceData?.show_invoice_mrp;
	const showSaved = invoiceData?.show_invoice_saved;
	const productData = isQuotation ? data.quotationProduct : data.saleInvoiceProduct;
	const noOfDecimalDigits = _.get(productSettings, "noOfDecimalDigits", 0);

	const getDiscount = () => {
		let totalDiscount = _.reduce(productData, (discount, product) => {
			const productMrpPrice = _.get(product, "product.productMrpPrice", 0);
			const productSalePrice = _.get(product, "productSalePrice", 0);
			const productQuantity = _.get(product, "productQuantity", 0);
			if (productSalePrice && productMrpPrice) {
				return discount + ((productMrpPrice - productSalePrice) * productQuantity);
			}
			return discount
		}, 0);
		const totalAmount = _.get(data, "totalAmount");
		const grandTotal = _.get(data, "grandTotal");
		if (totalAmount && grandTotal)
			totalDiscount = totalDiscount + totalAmount - grandTotal;
		return totalDiscount;
	}
	const totalDiscount = DECIMAL_NUMBER(getDiscount(), noOfDecimalDigits);
	return (
		<Fragment>
			<div ref={ref} className=''>
				<div className='pos-print-body w-[240px] m-auto text-sm'>
					<div className='bill'>
						<div className='mt-1 w-[236px]'>
							{isValidUrl(invoiceData?.companyLogo) && <img className="min-w-[50px] max-w-[150px] min-h-[50px] max-h-[80px] object-contain m-auto" src={invoiceData?.companyLogo}></img>}
							<h3 className='font-bold uppercase !text-xl'>{invoiceData?.companyName}</h3>
							{/* {invoiceData?.gst_no && <div className="text-center text-base font-semibold mb-1">{invoiceData?.gst_no}</div>} */}
							{invoiceData?.tagLine && <div className='tagline'>{invoiceData?.tagLine}</div>}
							<div className='address'>
								{invoiceData?.address && <span>{invoiceData?.address}</span>} <br />
								{invoiceData?.address_l2 && <span>{invoiceData?.address_l2}</span>} <br />
								<div>{invoiceData?.phone && <span>{invoiceData?.phone}</span>}</div>
							</div>
							{/* {invoiceData?.email && <div className='email'>{invoiceData?.email}</div>}
							{invoiceData?.website && <div className='website'>{invoiceData?.website} </div>} */}

							<div className=" border-dashed border-t-2 border-[#000] mb-1">
								<div className='flex justify-between capitalize mt-1'>
									<div className='text-left'><b>{isQuotation ? "Quote" : "Bill"} No:</b> {data?.manualId}</div>
									{/* {data?.customer?.phone && <div>{data?.customer?.phone}</div>} */}
									<div className='text-left'>
										{invoiceData?.show_table_number && data?.tableNo && (<div><b>Table No:</b> {data?.tableNo}</div>)}
										<div > {getMomentObj(data?.date).format("DD-MM-YYYY")} <b>/</b> {invoiceData?.Show_time_invoice === true && (<span>{getMomentObj(data?.time).format("HH:mm:ss")}</span>)}</div>
									</div>
								</div>
								<div className="text-left">
									{/* <div className='flex'><b>{isQuotation ? "Quote" : "Customer ID"} : </b> {data?.customer?.id && <div>{data?.customer?.id}</div>}</div> */}
									<div className=''> {data?.customer?.name && <div>{data?.customer?.name}</div>}</div>
								</div>
							</div>
						</div>
					</div>

					<table className='border-b border-dotted border-[#2d2d2d] py-2 text-center'>
						<tr className='header border-t border-b border-dotted py-2 border-[#2d2b2b] mb-5'>
							{/* <th>SR</th> */}
							<th style={{ width: "100px" }} className="text-left">ITEM</th>
							{showMrp ? <th>MRP</th> : null}
							<th>RATE</th>
							<th>QTY</th>
							<th>AMT</th>
						</tr>
						{data &&
							_.filter(productData, (each) => each).map((d, i) => (
								<tr key={d.id} className='data'>
									{/* <td>{i + 1}</td> */}
									<td style={{ width: "100px" }} className=" text-left ">{d.product?.name}</td>
									{showMrp ? <td >{d.productMrpPrice || d.product?.productMrpPrice}</td> : null}
									<td>{d.productSalePrice}</td>
									<td>{d.productQuantity}</td>
									<td>{DECIMAL_NUMBER(d.productQuantity * d.productSalePrice, noOfDecimalDigits)}</td>
								</tr>
							))}
					</table>

					<div className=" mt-1 w-full px-0.5">
						{/* <div className='flex justify-end w-full'>
							<td>Total Items</td>
							<td>
								{data.saleInvoiceProduct?.reduce( (totalQty, item) => totalQty + item.productQuantity, 0)}
							</td>
						</div>

						<div className='flex justify-between'>
							<td>Total Amount</td>
							<td>{data.totalAmount - vatAmount}</td>
						</div> */}

						{storeGSTCode === customerGSTCode || !customerGSTCode ? <div>
							{vatAmount !== 0 && <div className='flex justify-end w-full'>
								<span className="w-full text-left ">CGST</span>
								<span className="w-full text-right ">₹{DECIMAL_NUMBER(vatAmount / 2, noOfDecimalDigits)}</span>
							</div>}

							{vatAmount !== 0 && <div className='flex justify-end w-full'>
								<span className="w-full text-left ">SGST</span>
								<span className="w-full text-right ">₹{DECIMAL_NUMBER(vatAmount / 2, noOfDecimalDigits)}</span>
							</div>}
						</div> :
							<div>
								{vatAmount !== 0 && <div className='flex justify-end w-full'>
									<span className="w-full text-left ">IGST</span>
									<span className="w-full text-right ">₹{vatAmount}</span>
								</div>}
							</div>}

						{/* {data?.productDiscount != 0 && <div className='flex justify-end w-full'>
							<span className="w-full text-left">Discount</span>
							<span className="w-full text-right">₹{data?.productDiscount}</span>
						</div>} */}

						{data?.totalAmount != 0 && <div className='flex justify-end w-full'>
							<span className="w-full text-left ">Sub Total</span>
							<span className="w-full  text-right ">₹{data.totalAmount}</span>
						</div>}

						{data?.returnTotal > 0 && <div className='flex justify-end w-full'>
							<span className="w-full text-left">Return Total</span>
							<span className="w-full  text-right">- ₹{data.returnTotal}</span>
						</div>}

						{data?.discount != 0 && <div className='flex justify-end w-full'>
							<span className="w-full text-left ">Discount(-)</span>
							<span className="w-full  text-right ">{data?.discount_type == "amount" ? "₹" : " "}{data?.discount}{data?.discount_type == "percentage" ? "%" : " "} </span>
						</div>}

						<div className='w-full'>
							<div style={{ fontSize: 15 }} className="flex justify-end w-full font-bold border-t border-dashed border-[#4a4a4a] py-0.5">
								<div className="flex">
									<span className="text-left "> ITEMS:</span>
									<span className=" pl-0.5">{data?.productQty}</span>
								</div>
								<span className="w-full text-right">TOTAL Rs. {data?.grandTotal}</span>
							</div>
							<div className="flex justify-center w-full border-t-2 border-dashed border-[#000]">
								{showSaved && totalDiscount ?
									<>
										<span style={{ fontSize: 15, paddingTop: "5px" }} className="w-full ">** You Saved</span>
										<span style={{ fontSize: 15, paddingTop: "5px" }} className=""><pre> - </pre></span>
										<span style={{ fontSize: 15, paddingTop: "5px" }} className="w-full font-semibold">₹{totalDiscount} **</span>
									</> : null}
							</div>
						</div>
					</div>

					{invoiceData?.footer}
				</div>
			</div>
		</Fragment>
	);
});

const PosPrint3inch1 = ({ data, vatAmount, styles, successCallBack, preCall, isQuotation = false }) => {
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onAfterPrint: () => successCallBack?.(),
	});
	const invoiceData = useSelector((state) => state.accounts.appSettings);
	const productSettings = useSelector((state) => state.accounts.productSettings);

	return (
		<div>
			<div className='hidden'>
				<PrintToPdf
					ref={componentRef}
					data={data}
					invoiceData={invoiceData}
					productSettings={productSettings}
					vatAmount={vatAmount}
					isQuotation={isQuotation}
				/>
			</div>
			{invoiceData && (
				<Button style={styles} type='primary' shape='round' onClick={() => {
					if (_.isFunction(preCall)) {
						preCall(handlePrint);
					} else {
						handlePrint()
					}
				}}>
					3" POS3
				</Button>
			)}
		</div>
	);
};

export default PosPrint3inch1;

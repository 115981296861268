import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import React, { useState } from "react";
import UserPrivateComponent from "../PrivacyComponent/UserPrivateComponent";

export default function CreateDrawer({
  title,
  width,
  permission,
  children,
  update,
  color,
  className = ""
}) {
  // Drawer state
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };
  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setOpen });
    }
    return child;
  });

  return (
    <>
      <UserPrivateComponent permission={permission}>
        <button
          onClick={(e) => {
            e.preventDefault()
            setOpen(true)
          }}
          className={`xs:px-2 px-1 text-md md:text-base py-1 lg:px-2  border ${
            color ? color : "bg-teal-700"
          } hover:bg-teal-500 text-white font-semibold md:font-normal rounded cursor-pointer h-[38px] ${className}`}
        >
          <div className='flex items-center gap-1'>
            {update ? <EditOutlined /> : <PlusOutlined />}
            <div className='min-w-[90px]'>{title}</div>
          </div>
        </button>
        <Drawer
          width={
            window.innerWidth <= 768 ? "100%" : width ? `${width}%` : "60%"
          }
          title={`${title}`}
          placement='right'
          onClose={onClose}
          open={open}
        >
          {open && <div className='px-4 pt-2'> {childrenWithProps}</div>}
        </Drawer>
      </UserPrivateComponent>
    </>
  );
}
